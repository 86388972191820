function initGenderSwitch() {
  if($('.genderswitch.uninitialized').length) {
    $(this).removeClass('uninitialized');
    
    // Switch value of gender selector when switch-element is clicked
    $('.genderswitch-label').on('click touchend', function() {
      if($(this).parent('.genderswitch').find('.genderswitch-checkbox').prop("value") > 0) {
        $(this).parent('.genderswitch').find('.genderswitch-checkbox').prop("value", 0);
        $(this).find('.genderswitch-switch').removeClass('glyphicons-woman').addClass('glyphicons-user');
      } else {
        $(this).parent('.genderswitch').find('.genderswitch-checkbox').prop("value", 1);
        $(this).find('.genderswitch-switch').removeClass('glyphicons-user').addClass('glyphicons-woman');
      }
    });
  } else {
    setTimeout(initGenderSwitch,100);
    
  }
}
