$(function () {
  // Show notice for GoogleMap-Nodes if user has not consented to cookies
  if (!window.userConsentedToCookies && !document.body.classList.contains('neos-backend')) {
    var gdprLink = '#';

    var footerMenu = document.querySelector('footer > div.footer-top > div.footer-menu');
    var privacyPageItem = footerMenu.querySelector('a[title="Datenschutz"]');

    if (privacyPageItem && privacyPageItem.hasAttribute('href')) {
      gdprLink = privacyPageItem.getAttribute('href');
    }

    $('.google-map-canvas').html('<p class="noconsent-notice"><b>Hinweis!</b><br>Wir können Ihnen die interaktive Karte aufgrund Ihrer aktuellen Cookie-Einstellungen leider nicht zur Verfügung stellen. Weitere Informationen erhalten Sie in unserer <a href="' + gdprLink + '" target="_self">Datenschutzerklärung</a>.</p>');
  }
})