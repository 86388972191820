var pageCloud = function($){
	'use strict';

	return {
		setPageCloudSelector: function(selector) {
			var pageClouds = [];
			$(selector).each(function(){
				pageClouds.push($(this).children('a'))
			})

			this.pageCloudItems = pageClouds;

		},
		init: function () {
			this.pageCloudItems.forEach(function(item){
				$(item).tagcloud({
			    size: {start: 12, end: 22, unit: "px"}
			  })
			})
		}
	};

}(jQuery);
