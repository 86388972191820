var backgroundVideos = new function() {
  this.init = function () {
    if($('body > #home').length && $('main > div.content').data('videos') && typeof(document.createElement('video').canPlayType) != 'undefined') {
      var content = $('main > div.content');
      var videos = JSON.parse('[' + content.data('videos') + ']');
      var video = videos[Math.floor(Math.random() * videos.length)];

      var domElement = app.templates.Video({source: video});
      content.prepend(domElement);

      window.onresize = function() {
        if (content.outerWidth() > content.find('video').width()) {
          content.find('video').css({'height': 'auto', 'width': '100%'});
        }

        if (content.outerHeight() > content.find('video').height()) {
          content.find('video').css({'height': '100%', 'width': 'auto'});
        }
      }

      content.find('video').on('loadeddata', function() {
        $(window).trigger('resize');
      });
    } else {
      if ($('main > div.content').data('background-image')) {
        $('main > div.content').css('background-image', 'url(' + $('main > div.content').data('background-image') + ')');
      }
    }
  }
}

function initSponsorsSwiper() {
  window.sponsors = window.sponsors || [];

  $('.sponsors').each(function (i,e,a) {
    window.sponsors[i] = new Swiper(this,{
      speed: 1500,
      autoplay: {
        delay: 4000,
        stopOnLast: false,
        disableOnInteraction: false
      },
      slidesPerView: 1,
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })

    $(window).on('resize',function () {
      window.sponsors[i].update();
    });
  })
}
