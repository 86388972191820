// Responsive breakpoints
var smBreakpoint = 768;
var mdBreakpoint = 992;
var lgBreakpoint = 1200;

$(document).ready(function(){
  showContactDetails();
  initSubMenuManager();
  initSearch();
  initGenderSwitch();
  initCloseAlert();
  initSearchWithEnter();
  initSponsorsSwiper();
  initEventSelector();
  mainMenu.init();
  backgroundVideos.init();
  pageCloud.setPageCloudSelector('.page-cloud');
  pageCloud.init();

  // Close Swipebox-Image on Document.Body
  $(document.body).on('click touchend','#swipebox-slider .current img', function(e) {
      return false;
  }).on('click touchend','#swipebox-slider .current', function(e) {
      $('#swipebox-close').trigger('click');
  });

  setLinkTargets();
});

$(window).resize(function(){
  showContactDetails();
  initSubMenuManager();
});
