function showContactDetails() {

  if($('#addon-collection').find('.contact-inner>.neos-contentcollection').html() === null || $('#addon-collection').find('.contact-inner>.neos-contentcollection').html() === undefined || $('#addon-collection').find('.contact-inner>.neos-contentcollection').html() === '') {
    if($('#neos-application').length < 1) {
      $('#addon-collection').hide(0);
    }
  }

  if ($(window).outerWidth() < mdBreakpoint) {

    $('#addon-button, #openings-button, #contact-button').each(function() {
      if($(this).attr('href') === null || $(this).attr('href') === undefined || $(this).attr('href') === '') {
        $(this).attr('href','#');
      }
    });

    $('#contact-button, #addon-button, #openings-button').on('click', function(event){
      event.preventDefault();

      var popups = [];
      if($(this).attr('id') !== 'contact-button') {
        popups.push($('#contact-button').next().next().get(0));
      }
      if($(this).attr('id') !== 'openings-button') {
        popups.push($('#openings-button').next().next().get(0));
      }
      if($(this).attr('id') !== 'addon-button') {
        popups.push($('#addon-button').next().next().get(0));
      } 

      popups.forEach(function(current) {
        $(current).removeClass('active');
      })

      $(this).next().next().toggleClass('active');

    });

  }

  if ($(window).outerWidth() >= mdBreakpoint ) {

    $('#addon-button, #openings-button').removeAttr('href');

  }

  $('.close-contact, .close-openings').on('click', function(event){
    event.preventDefault();

    $(this).parent('div').removeClass('active');
  });

}
