function initEventSelector() {
  var eventselector = document.querySelector('form div.event-selector');
  if (eventselector === null) {
    setTimeout(initEventSelector, 250);
    return;
  }

  eventselector.addEventListener('change', function (event) {
    var childcareSelects = eventselector.querySelectorAll('input[type="checkbox"][id*="kinderbetreuung"]');

    for (var i = 0; i < childcareSelects.length; i++) {
      if (childcareSelects[i].checked) {
        // add required to childcare inputs and show section if at least one such checkbox is checked
        document.querySelectorAll('form .required-for-childcare').forEach(function (item) {
          item.classList.remove('hidden');
          item.querySelectorAll('input').forEach(function (input) {
            input.setAttribute('required', 'required');
          });
        });

        return;
      }
    }

    // otherwise remove all required from childcare inputs and hide section
    document.querySelectorAll('form .required-for-childcare').forEach(function (item) {
      item.classList.add('hidden');
      item.querySelectorAll('input').forEach(function (input) {
        input.removeAttribute('required');
      });
    });
  });
}
