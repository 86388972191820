var maxOffset = 12;

function initSearch() {
  activateSubmitButton();

  $(document.body).on('submit', '.neos-search.ajax form', function(event) {
    var form = this;

    event.preventDefault();

    if (event.originalEvent !== undefined) {
      submitSearchRequest(form);
      searchResultAnimation(form);
    }
  });

  $('.search-results-wrapper').on('scroll', function() {
    var offsetTop = parseInt($('.search-results').children().first().position().top * -1);
    var offsetTop = offsetTop > maxOffset ? maxOffset : offsetTop;

    $('.contact-details').css('top', offsetTop);
  });

  function activateSubmitButton() {
    $(document.body).on('click', '.neos-search.ajax form .submit', function() {
      var form = $(this).parent('form').get(0);

      submitSearchRequest(form);
      searchResultAnimation(form);

      $(this).removeClass('submit').addClass('submitted');
    });
  }

  function showCloud() {
		$('.page-cloud').show();
		$('.page-cloud').parent().find('.gummersbach-mainwebsite-headline h1').stop().animate({opacity: 1});
	}

	function hideCloud() {
		$('.page-cloud').hide();
		$('.page-cloud').parent().find('.gummersbach-mainwebsite-headline h1').stop().animate({opacity: 0});
	}

  function searchResultAnimation(form) {
    var resultsWrapper = $(form).next('.search-results-wrapper');
    var transitionContainer = $(form).parents('.transition-container');

    transitionContainer.addClass('transition-container-active');
    resultsWrapper.addClass('search-results-active');

    resetForm(transitionContainer,resultsWrapper,form);
  }


  function submitSearchRequest(form) {
    $(form).find('.submit').children('i').removeClass('glyphicons-search').addClass('glyphicons-remove');
    $('.neos-search.ajax form .submit').removeClass('submit').addClass('submitted');
    hideCloud();
    getSearchResults($(form).serializeArray(), form);
  }

  function resetForm(transitionContainer,resultsWrapper,form) {
    $(document.body).on('click', '.neos-search.ajax form .submitted', function(e) {
      e.preventDefault();

      showCloud();

      $(this).children('i').removeClass('glyphicons-remove').addClass('glyphicons-search');
      transitionContainer.removeClass('transition-container-active');
      resultsWrapper.removeClass('search-results-active');

      resultsWrapper.children('.search-results').html('');
      $(form).find('.search-param').val('');

      $(this).removeClass('submitted').addClass('submit');
    });
  }

  function activateContactDetails() {
    $('.search-results').on('click', function(e) {
      if ($(e.target).parents('.contact-details').length == 0 && !$(e.target).is('.contact-details') && $(e.target).parents('.activate-contact-details').length == 0 && !$(e.target).is('.activate-contact-details') && $('.search-results').hasClass('contact-person-details-active')) {
        e.preventDefault();
        $('.contact-details-active').add($('.contact-person-details-active')).add($('.activate-contact-details .glyphicons')).removeClass('contact-details-active contact-person-details-active rotate');
      }
    });

    $('.activate-contact-details').on('click', function(){
      if ($('.activate-contact-details .glyphicons').hasClass('rotate')) {
        $('.contact-details-active').add($('.contact-person-details-active')).add($('.activate-contact-details .glyphicons')).removeClass('contact-details-active contact-person-details-active rotate');
        return false;
      }

      var button = $(this).children('.glyphicons');
      var contactDetailsCanvas = $(this).parent().parent().next();
      var contactPersonDetails = $(this).parent().parent().parent().parent();

      $('.search-results-wrapper').addClass('contact-details-active');
      button.addClass('rotate');
      contactDetailsCanvas.addClass('contact-details-active');
      contactPersonDetails.addClass('contact-person-details-active');
    });
  }

  function getSearchResults(data, form) {
    $(form).next('.search-results-wrapper').children('.search-results').html('<li class="text-center loader"><i class="fa fa-spinner fa-pulse"></i><p>Suchergebnisse werden geladen!</p></li>');

    var searchParameter = '';
    var currentPath = '';
    var submitForm = 0;

    $.each(data, function(index, value) {
      if (value.name == 'currentNodePath') {
        currentPath = value.value;
      }

      if (value.name == 'searchParameter') {
        searchParameter = value.value;
      }

      if (value.name == 'submitForm') {
        submitForm = value.value;
      }
    });

    var dimension = $(form).closest('.neos-search').attr('data-dimension');
    var searchAjaxUrl = location.protocol + '//' + location.host + '/search/'  + ( dimension != '' ? dimension + '/' : '' );

    $.ajax({
      url: searchAjaxUrl,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({searchParameter: searchParameter, currentNodePath: currentPath, submitForm: submitForm}),
      dataType: 'json',
      timeout: 0
    }).done(function(data) {

      $(form).next('.search-results-wrapper').children('.search-results').html('');

      var results = data.results;
      var count = data.resultsCount;

      if (results.length) {
        var documentNodeTemplates = [];
        var contactPersonTemplate = [];
        var assetTemplate = [];

        $.each(results, function(index, value) {
          var contactPersonPartial;

          if (value.data.contactPerson) {
            contactPersonPartial = app.templates.searchResultItem.contactPerson({
              data: value.data.contactPerson,
              metaData: value.metaData
            })
          }

          switch (value.type) {

            case 'documentNode':
              documentNodeTemplates = app.templates.searchResultItem.documentNode({
                data: value.data,
                searchParameter: searchParameter,
                metaData: value.metaData,
                contactPersonPartial: contactPersonPartial
              });
              $(form).next('.search-results-wrapper').children('.search-results').append(documentNodeTemplates);
              break;

            case 'contactPerson':
              contactPersonTemplate = app.templates.searchResultItem.contactPerson({
                data: value.data,
                metaData: value.metaData
              });
              $(form).next('.search-results-wrapper').children('.search-results').append(contactPersonTemplate);
              break;

            case 'asset':
              assetTemplate = app.templates.searchResultItem.asset({
                data: value.data,
                metaData: value.metaData
              });
              $(form).next('.search-results-wrapper').children('.search-results').append(assetTemplate);
              break;
          }
        });

        activateContactDetails();

        maxOffset = parseInt($(form).next('.search-results-wrapper').children('.search-results').height()) - parseInt($(form).next('.search-results-wrapper').height());

      } else {

        var noResultsTemplate = app.templates.noResults({
          searchTerm: searchParameter
        });

        $(form).next('.search-results-wrapper').children('.search-results').append(noResultsTemplate);
      }
    }).error(function(jqXHR, exception, errorThrown){

      $(form).next('.search-results-wrapper').children('.search-results').html('');

      var errorResultsTemplate = app.templates.errorResults({
        searchTerm: searchParameter,
        message: errorThrown
      });

      $(form).next('.search-results-wrapper').children('.search-results').append(errorResultsTemplate);
    });
  }
}
