this["app"] = this["app"] || {};
this["app"]["templates"] = this["app"]["templates"] || {};
this["app"]["templates"]["ContactPersons"] = this["app"]["templates"]["ContactPersons"] || {};
this["app"]["templates"]["ContactPersons"]["CloseButton"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"contact-persons-back-wrapper\">\n  <a class=\"contact-persons-back\"></a>\n</div>\n";
},"useData":true});
this["app"]["templates"]["ContactPersons"]["ContactPerson"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a href=\"tel:"
    + alias4(((helper = (helper = helpers.phoneLink || (depth0 != null ? depth0.phoneLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phoneLink","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.phone || (depth0 != null ? depth0.phone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phone","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <div>"
    + container.escapeExpression(((helper = (helper = helpers.fax || (depth0 != null ? depth0.fax : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fax","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a href=\"mailto:"
    + alias4(((helper = (helper = helpers.mailLocal || (depth0 != null ? depth0.mailLocal : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mailLocal","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.mailDomain || (depth0 != null ? depth0.mailDomain : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mailDomain","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.mailLocal || (depth0 != null ? depth0.mailLocal : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mailLocal","hash":{},"data":data}) : helper)))
    + "<br/>"
    + alias4(((helper = (helper = helpers.mailDomain || (depth0 != null ? depth0.mailDomain : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mailDomain","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"contact-person\" data-department=\""
    + alias4(((helper = (helper = helpers.departmentId || (depth0 != null ? depth0.departmentId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"departmentId","hash":{},"data":data}) : helper)))
    + "\" data-ressort=\""
    + alias4(((helper = (helper = helpers.ressortId || (depth0 != null ? depth0.ressortId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ressortId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"salutation\">"
    + alias4(((helper = (helper = helpers.salutation || (depth0 != null ? depth0.salutation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"salutation","hash":{},"data":data}) : helper)))
    + "</div>\n  <div class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n  <div class=\"department\">"
    + ((stack1 = ((helper = (helper = helpers.department || (depth0 != null ? depth0.department : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"department","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div class=\"address\">"
    + ((stack1 = ((helper = (helper = helpers.address || (depth0 != null ? depth0.address : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div class=\"contact\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.phone : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fax : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mailLocal : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true});
this["app"]["templates"]["ContactPersons"]["FilterItem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " data-ressort=\""
    + container.escapeExpression(((helper = (helper = helpers.ressort || (depth0 != null ? depth0.ressort : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ressort","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li>\n  <a href=\"#\" data-department=\""
    + alias4(((helper = (helper = helpers.department || (depth0 != null ? depth0.department : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"department","hash":{},"data":data}) : helper)))
    + "\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ressort : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a>\n  "
    + ((stack1 = ((helper = (helper = helpers.subFilter || (depth0 != null ? depth0.subFilter : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subFilter","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n</li>\n";
},"useData":true});
this["app"]["templates"]["ContactPersons"]["FilterList"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " class=\""
    + container.escapeExpression(((helper = (helper = helpers["class"] || (depth0 != null ? depth0["class"] : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <div class=\"contact-persons-title\">\n      "
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)))
    + "\n      <a class=\"contact-persons-back\"></a>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<ul"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["class"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n  "
    + ((stack1 = ((helper = (helper = helpers.closeButton || (depth0 != null ? depth0.closeButton : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"closeButton","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = ((helper = (helper = helpers.filterItems || (depth0 != null ? depth0.filterItems : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filterItems","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n</ul>\n";
},"useData":true});
this["app"]["templates"]["ContactPersons"]["SearchInput"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"contact-persons-search\">\n  <input type=\"text\" class=\"form-control contact-persons-search-input\" autocomplete=\"off\" placeholder=\"Name, Position, Telefon etc.\">\n  <span class=\"glyphicons glyphicons-search\"></span>\n</div>\n";
},"useData":true});
this["app"]["templates"]["Video"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"home-video\">\n  <video preload autoplay loop muted>\n    <source src=\""
    + container.escapeExpression(((helper = (helper = helpers.source || (depth0 != null ? depth0.source : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"source","hash":{},"data":data}) : helper)))
    + "\" type=\"video/mp4\">\n  </video>\n</div>\n";
},"useData":true});
this["app"]["templates"]["errorResults"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"no-results\">\n  <p>Ihre Suche nach:</p>\n\n  <h4>\""
    + alias4(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\"</h4>\n\n  <p>ergab leider einen Fehler:</p>\n\n  <code>"
    + alias4(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "</code>\n</li>";
},"useData":true});
this["app"]["templates"]["noResults"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<li class=\"no-results\">\n  <p>Leider konnten wir zu Ihrem Suchbegriff:</p>\n\n  <h4>\""
    + container.escapeExpression(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\"</h4>\n\n  <p>keine Treffer ermitteln! Bitte versuchen Sie es erneut mit einem anderen Suchbegriff.</p>\n  <p>Vielen Dank.</p>\n</li>";
},"useData":true});
this["app"]["templates"]["searchResultItem"] = this["app"]["templates"]["searchResultItem"] || {};
this["app"]["templates"]["searchResultItem"]["asset"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<li class=\"search-result-item \">\n  <a class=\"result-wrapper\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.uri : stack1), depth0))
    + "\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.title : stack1), depth0))
    + "\">\n    <div class=\"result-item-main pull-left\">\n      <h5>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h5>\n      <span>Stand: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.lastModified : stack1), depth0))
    + "</span>\n    </div>\n    <div class=\"item-footer pull-right\">\n      <i class=\"filetypes filetypes-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.fileextension : stack1), depth0))
    + "\"></i>\n    </div>\n    <div class=\"clearfix\"></div>\n  </a>\n</li>\n";
},"useData":true});
this["app"]["templates"]["searchResultItem"]["contactPerson"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <div>\n              <i class=\"glyphicons glyphicons-iphone\"></i>\n              <a href=\"tel:"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.linkableMobile : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mobile : stack1), depth0))
    + "</a>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div>\n              <i class=\"glyphicons glyphicons-fax\"></i>\n              <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.fax : stack1), depth0))
    + "</span>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <div>\n              <i class=\"glyphicons glyphicons-door\"></i>\n              <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.floor : stack1), depth0))
    + "</span>\n              <br>\n              <div id=\"room\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.room : stack1), depth0))
    + "</div>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<li class=\"search-result-item\">\n  <div class=\"result-wrapper\">\n    <div class=\"result-item-main pull-left\">\n      <h5 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.metaData : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.salutation : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.firstname : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.lastname : stack1), depth0))
    + "</h5>\n      <a href=\"tel:"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.linkablePhone : stack1), depth0))
    + "\"> <i class=\"glyphicons glyphicons-earphone\"></i> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</a>\n      <a href=\"mailto:"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailAlias : stack1), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailDomain : stack1), depth0))
    + "\"><i class=\"glyphicons glyphicons-envelope\"></i>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailAlias : stack1), depth0))
    + "<span class=\"hidden-xs\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailDomain : stack1), depth0))
    + "</span></a>\n    </div>\n    <div class=\"item-footer pull-right\">\n      <button class=\"activate-contact-details\"><i class=\"glyphicons glyphicons-chevron-right\"></i></button>\n    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n  <div class=\"contact-details "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.metaData : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">\n    <div id=\"contact-person-nodetype\">\n      <div id=\"contact-icon\"><i class=\"glyphicons glyphicons-vcard\"></i></div>\n      <div class=\"clearfix\"></div>\n      <div class=\"contact-inner\">\n        <div class=\"salutation\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.salutation : stack1), depth0))
    + "</div>\n        <h5>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.firstname : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.lastname : stack1), depth0))
    + "</h5>\n\n        <div class=\"contact-body\">\n          <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.competence : stack1), depth0))
    + "</p>\n          <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.department : stack1), depth0))
    + "</p>\n\n          <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.ressort : stack1), depth0))
    + "</p>\n\n          <div class=\"spacing\"></div>\n\n          <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.street : stack1), depth0))
    + "</p>\n          <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.zip : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.city : stack1), depth0))
    + "</p>\n        </div>\n\n        <div class=\"contact-footer\">\n          <div class=\"clearfix\"></div>\n          <!--Phone-->\n          <div>\n            <i class=\"glyphicons glyphicons-earphone\"></i>\n            <a href=\"tel:"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.linkablePhone : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</a>\n          </div>\n\n          <!--Mobile-->\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mobile : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <!--Fax-->\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.fax : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <!--Mail-->\n          <div id=\"mail\">\n            <i class=\"glyphicons glyphicons-envelope\"></i>\n            <a href=\"mailto:"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailAlias : stack1), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailDomain : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailAlias : stack1), depth0))
    + "<br>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.mailDomain : stack1), depth0))
    + "</a>\n          </div>\n\n          <!--Room Floor-->\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.floor : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</li>\n";
},"useData":true});
this["app"]["templates"]["searchResultItem"]["documentNode"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "    "
    + ((stack1 = ((helper = (helper = helpers.contactPersonPartial || (depth0 != null ? depth0.contactPersonPartial : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contactPersonPartial","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <li class=\"search-result-item\">\n    <a  class=\"result-wrapper\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.uri : stack1), depth0))
    + "?="
    + alias2(((helper = (helper = helpers.searchParameter || (depth0 != null ? depth0.searchParameter : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"searchParameter","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.title : stack1), depth0))
    + "\">\n      <div class=\"result-item-main pull-left\">\n        <h5 class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.metaData : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h5>\n        <div class=\"text-teaser\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.teaserText : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n      </div>\n      <div class=\"item-footer pull-right\">\n        <div class=\"category-number "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.metaData : depth0)) != null ? stack1.color : stack1), depth0))
    + "\">\n          "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.metaData : depth0)) != null ? stack1.categoryId : stack1), depth0)) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"category-title\">\n          "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.metaData : depth0)) != null ? stack1.title : stack1), depth0)) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n      <div class=\"clearfix\"></div>\n    </a>\n  </li>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.contactPerson : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"useData":true});